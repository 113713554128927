if(document.querySelector('.select-listing')){
  let selectList = document.querySelectorAll('.select-listing');

    selectList.forEach(function(el){
      let selectBtn = el.querySelector('.select-btn');

        selectBtn.addEventListener('click', function(){
          if(el.classList.contains('open')){
              el.classList.remove('open');
              this.setAttribute('aria-expanded', false);
            } else {
              el.classList.add('open');
              this.setAttribute('aria-expanded', true);
            }
        });
    });
}