// navigation mob
// menu
if(document.querySelector('.hamburger')){
	let btnMenu = document.querySelector('.hamburger'),
  		menuMob = document.querySelector('.mobile-navigation'),
  		menuClose = menuMob.querySelector('.close'),
  		overlyMenu = document.querySelector('.overly'),
      	links = menuMob.querySelectorAll('a'),
  		body = document.body;

  	btnMenu.addEventListener('click', function(){
  		if(menuMob.classList.contains('open')){
	  		menuMob.classList.remove('open');
	  		body.classList.remove('body_scroll');
	  		overlyMenu.style.display = 'none';
	  		this.setAttribute('aria-expanded', false);
	  	} else {
	  		menuMob.classList.add('open');
	  		body.classList.add('body_scroll');
	  		overlyMenu.style.display = 'block';
	  		this.setAttribute('aria-expanded', true);
	  	}
  	})

  	overlyMenu.addEventListener('click', function(){
  		btnMenu.setAttribute('aria-expanded', false);
  		menuMob.classList.remove('open');
  		body.classList.remove('body_scroll');
  		overlyMenu.style.display = 'none';
  	});

  	menuClose.addEventListener('click', function(){
  		btnMenu.setAttribute('aria-expanded', false);
  		menuMob.classList.remove('open');
  		body.classList.remove('body_scroll');
  		overlyMenu.style.display = 'none';
  	});

    links.forEach(function(el){
      el.addEventListener('click', function(){
        menuMob.classList.remove('open');
        body.classList.remove('body_scroll');
        overlyMenu.style.display = 'none';
        btnMenu.setAttribute('aria-expanded', false);
      });
    });
}