// faq
if(document.querySelector('.faq')){
  let faqBlocks = document.querySelectorAll('.faq__item');

    faqBlocks.forEach(function(el){
      let faqBtn = el.querySelector('.heading');

      faqBtn.addEventListener('click', function(){

        if(el.classList.contains('open') == false){
          el.classList.add('open');
        } else {
          el.classList.remove('open');
        }
      })
    })
}