// scroll header
if (document.querySelector('.header')) {
    function scrollUp(){
      return window.pageYOffset || document.documentElement.scrollTop;
    }

  let header = document.querySelector('.header'),
      sticky = header.offsetTop;

  window.addEventListener('scroll', function(){
    if (scrollUp() > 0) {
        header.classList.add('header_fixed');
      } else {
        header.classList.remove('header_fixed');
      }
  });

  if(header.classList.contains('header-main')){
      let logo = document.querySelector('.header-main .logo img');

      window.addEventListener('scroll', function(){
        if (scrollUp() > 0) {
            logo.src = 'img/logo-black.svg';
          } else {
            logo.src = 'img/logo-white.svg';
          }
      });
    }
}
